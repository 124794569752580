<template>
  <div class="card">
    <div class="card-header">
      <h6 style="display: inline-block;"><i class="fa-duotone fa-calendar-star"></i> Agenda</h6>
    </div>
    <div class="card-body">

      <table class="table-striped table table-sm">
        <thead>
        <tr>
          <th>Mitglied</th>
          <th>Name</th>
          <th>Check-In</th>
          <th >Check-Out</th>
          <th @click="sortIt('participation')">Teilnahme</th>
          <th  @click="sortIt('le')">LE</th>
        </tr>
        <tr v-for="r in checkins">

          <td>{{ r.customer.c_customer_id }}</td>
          <td>{{ r.customer.c_lastname }} {{ r.customer.c_firstname }}</td>
          <template v-if="r.subeventsCheckin && Object.keys(r.subeventsCheckin).length > 0">
          <td>
            <template v-for="(subC, id) in r.subeventsCheckin"><small>{{$filters.formatDateTime(subC.checkin_time)}}</small><br></template>
          </td>
          <td>
            <template v-for="(subC, id) in r.subeventsCheckin"><small>{{$filters.formatDateTime(subC.checkout_time)}}</small><br></template>
          </td>
          </template>
          <template v-else>
            <td><small>{{ $filters.formatDateTime(r.checkin_time) }}</small></td>
            <td><small>{{ $filters.formatDateTime(r.checkout_time) }}</small></td>
          </template>
          <td>{{ r.participation }}</td>
          <td>{{ r.le }}</td>

        </tr>
        </thead>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'Checkins',
  props: {
    subevents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkins: [],
      direction: 'asc',
    }
  },
  components: {},
  computed: {
    ...mapGetters(["modalsList", "eventActive"])
  },
  methods: {
    sortIt($path) {


      if (this.direction === 'asc') {
        this.direction = 'desc';
      } else {
        this.direction = 'asc';
      }
      // sortCheckin alphabet
      this.checkins.sort((a, b) => {
        if (this.direction === 'asc') {
          return a[$path] > b[$path] ? 1 : -1;
        } else {
          return a[$path] < b[$path] ? 1 : -1;
        }
      });

    },
    getCheckins() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/checkins", {headers: headers})
        .then((response) => {
          this.checkins = response.data.result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.getCheckins();
  }
}
</script>

