<template>
  <div class="card">
    <h6 class="card-header">Events


      <div class="btn-group float-end">

        <button class="btn btn-outline-primary btn-sm" @click="showModal = true; modalType = 'learn'"><i
          class="fa-duotone fa-file-plus"></i> Lerneinheiten
        </button>
        <button class="btn btn-outline-primary btn-sm" @click="showModal = true; modalType = 'register'"><i
          class="fa-duotone fa-square-plus"></i> Anmeldung
        </button>
      </div>
    </h6>

<ul class="tabNav subtabs">
  <li @click="tab = 'le'" :class="(tab === 'le') ? 'active': ''">Lerneinheiten</li>
  <li @click="tab = 'event'"  :class="(tab === 'event') ? 'active': ''">Anmeldungen</li>
</ul>

    <div class="card-body"  v-if="tab === 'le'">
      <div class="row">
        <div class="col-lg-5 col-12">
          <label>Lerneinheiten in der aktuellen Laufzeit: </label>
        </div>
        <div class="col-lg-7 col-12">
          {{leData.pointsInCurrentPeriod}}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-12">
          <label>Gesamt: </label>
        </div>
        <div class="col-lg-7 col-12">
          {{leData.allPoints}}
        </div>
      </div>
      <hr>
      <div class="row" v-if="leData.license">
        <div class="col-lg-5 col-12">
          <label>Lizenz: </label>
        </div>
        <div class="col-lg-7 col-12">
         {{$filters.formatDate(leData.license.from)}} - {{$filters.formatDate(leData.license.to)}}
          <p v-if="leData.license.lastUpgrade" v-html="leData.license.lastUpgrade.a_description"></p>
          <template v-if="leData.license.lastUpgrade && leData.license.lastUpgrade.a_time">
          <label>Zeit</label> {{$filters.formatDateTime(leData.license.lastUpgrade.a_time)}}</template>
        </div>
      </div>


      <hr>
      <div class="points">
        <div class="mb-3 point">
          <table class="table table-striped-columns" v-if="leData.usedInCurrentPeriod">
            <thead>
            <tr>
              <th class="text-center">LE</th>
              <th>Datum</th>
              <th>Titel</th>
              <th>Beschreibung</th>
            </tr>
            </thead>
            <tbody>
            <tr  v-for="point in leData.usedInCurrentPeriod">
              <td class="text-center">{{point.le}}</td>
              <td>{{$filters.formatDate(point.start)}}</td>
              <td>{{point.title}}</td>
              <td><span v-html="point.description"></span></td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <template v-for="event in events">
        <div class="eventWrap">
          <strong>{{ event.title }}</strong>
          <p class="mb-1"><small>{{ $filters.formatDate(event.start) }} {{ $filters.formatTimeShort(event.start) }} -
            {{ $filters.formatDate(event.end) }} {{ $filters.formatTimeShort(event.end) }}</small></p>
          <div class="row">
            <div class="col-lg-4 col-12">
              <p class="mb-0"><strong>Status:</strong>&nbsp;
                <template v-if="event.regState === '1'"><i class="fa-duotone fa-check-circle" style="color: #3B8130;"></i> gebucht</template>
                <template v-if="event.regState === '0'"><i class="fa-duotone fa-xmark-circle" style="color: #b21f28;"></i> storniert</template>
                <template v-if="event.regState === '2'"><i class="fa-duotone fa-circle-pause"></i> Warteliste</template>
              </p>
            </div>
            <div class="col-lg-4 col-12">
              <p class="mb-0"><strong>Teilnahme:</strong> {{ $t('events.participation.' + event.participation) }}</p>
            </div>
            <div class="col-lg-4 col-12">
              <p class="mb-0"><strong>Angemeldet am:</strong> {{ $filters.formatDate(event.registration_date) }}
                {{ $filters.formatTimeShort(event.registration_date) }}</p>
            </div>
          </div>
        </div>
        <hr>
      </template>
    </div>
  </div>
  <modal v-if="showModal">
    <div class="modal fade show" tabindex="-1"
         style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" >
          <div class="modal-header">
            <h5 class="modal-title">
              Veranstaltung hinzufügen
            </h5>
            <div class="btn-group float-end" style="margin-left: auto;">
              <template v-if="modalType === 'register'">
                <button class="btn btn-outline-primary btn-sm" @click="saveEventReg"><i
                  class="fa-duotone fa-floppy-disk"></i> Speichern
                </button>
              </template>
              <template v-if="modalType === 'learn'">
                <button class="btn btn-outline-primary btn-sm" @click="saveEventLearn"><i
                  class="fa-duotone fa-floppy-disk"></i> Speichern
                </button>
              </template>
            </div>
            <button type="button" class="btn-close" @click="showModal = false; modalType = ''"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <VueMultiselect

                :options="selectEvents"
                :multiple="false"
                :internal-search="true"
                :close-on-select="true"
                :show-labels="false"
                @search-change="debouncedFetchOptions"
                valueProp="id"
                class="mb-3 small"
                :label="'preparedTitle'"
                locale="de"
                :placeholder="'Veranstaltungen suchen'"
                :showNoOptions="false"
                :noOptionsText="'Veranstaltungen suchen'"
                :noResultsText="'Veranstaltungen suchen'"
                :minChars="3"
                :filterResults="false"
                v-model="returnEvent"
              />
            </div>
            <div class="form-group" v-if="returnEvent.preparedTitle"><p><strong>Gewählte Veranstaltung</strong>: {{returnEvent.preparedTitle}}</p></div>
          <template v-if="modalType === 'register'">
            <div class="form-group">
              <label class="me-3">E-Mail senden</label>
              <div class="btn-group btn-group-yesno" role="group">
                <input type="radio" class="btn-check"  id="use_own_mailing_yes" value="yes" v-model="sendMail">
                <label class="btn btn-outline-success" for="use_own_mailing_yes">Ja</label>
                <input type="radio" class="btn-check" id="use_own_mailing_no" value="no" v-model="sendMail">
                <label class="btn btn-outline-danger" for="use_own_mailing_no">Nein</label>
              </div>
            </div>
          </template>
            <template v-if="modalType === 'learn'">
              <div class="form-group">
                <label class="me-3">Teilnahme</label>
                <div class="btn-group btn-group-yesno" role="group">
                  <input type="radio" class="btn-check"  id="participation_yes" value="complete" v-model="participation">
                  <label class="btn btn-outline-success" for="participation_yes">Komplett</label>
                  <input type="radio" class="btn-check" id="participation_no" value="incomplete" v-model="participation">
                  <label class="btn btn-outline-danger" for="participation_no">Teilweise</label>
                </div>
              </div>
              <div class="form-group">
                <label>Registrierdatum</label>
                <VueDatePicker v-model="registration_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy" placeholder="Registrierdatum"  :auto-apply="true" :close-on-auto-apply="true" locale="de"    :enable-time-picker="false" cancel-text="X"  :select-text="this.$t('glboals.choose')"  :teleport="true"   >

                </VueDatePicker>
              </div>
              <div class="form-group">
                <label>Lerneinheiten (LE)</label>
                <input type="number" class="form-control" v-model="le">
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueMultiselect from 'vue-multiselect'
import VueDatePicker from "@vuepic/vue-datepicker";
export default {
  name: "Events",
  computed: {
    ...mapGetters(["getEmployees", "customerActive"]),
  },
  props: {
    customer: {}
  },
  components: {
    VueMultiselect, VueDatePicker
  },
  data() {
    return {
      tab: 'event',
      events: [],
      leData: {},
      showModal: false,
      modalType: '',
      selectEvents:[],
      returnEvent: {},
      sendMail: 'yes',
      participation: 'complete',
      le: 0,
      registration_date: ''
    };
  },
  methods: {
    saveEventReg(){
      const payload = {}
      payload.dateId = this.returnEvent.id;
      payload.sendMail = this.sendMail;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/addEvent", payload,{headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        this.getEvents();
        this.showModal = false;
        this.modalType = '';
      });
    },
    saveEventLearn(){
      const payload = {}
      payload.dateId = this.returnEvent.id;
      payload.participation = this.participation;
      payload.le = this.le;
      payload.registration_date = this.registration_date;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/addLe", payload,{headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        this.getEvents();
        this.showModal = false;
        this.modalType = '';
      });
    },

    getEvents() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/events", {headers}).then((r) => {
        this.events = r.data.result.data;
        this.loading = false;
      });
      this.getLE();
    },
    getLE() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/le", {headers}).then((r) => {
        this.leData = r.data.result.data;
        this.loading = false;
      });
    },
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    },
    fetchOptions(searchTerm) {
      if (searchTerm.length >= 3) {

        let headers = {
          'crmAuth': localStorage.getItem('sketchManagerToken')
        };
        // prepare searchterm, strtolower and urlencode
        searchTerm = searchTerm.toLowerCase();
        searchTerm = encodeURIComponent(searchTerm);
        axios.get(this.$store.state.apiUrl + "event/search?limit=0&filter_aktuell=3&filter_search=" + searchTerm, {headers}).then((response) => {
          this.selectEvents = response.data.result.data;
        })
          .catch(error => {
            console.error(error);
          });
      } else {
        // Clear the options when the search term is less than 3 characters
        this.selectEvents = [];
      }
    }
  },
  created() {
    this.getEvents();
    this.debouncedFetchOptions = this.debounce(this.fetchOptions, 300);

  },
  watch: {
    customerActive() {
      this.getEvents();
    }
  }
};
</script>
